import { Injectable } from '@angular/core';

const USER_KEY = 'auth-user';
const TOKEN_KEY = 'auth-token';
const MENU_KEY = 'menu-open';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {
  constructor() {}

  clean(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return null;
  }

  public getToken(): string {
    const token = window.sessionStorage.getItem(TOKEN_KEY);
    if (token) {
      return token;
    }

    return null;
  }

  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }

  public isMenuOpen(): boolean {
    const menu = window.sessionStorage.getItem(MENU_KEY);
    if (menu === null) {
      return true;
    }
    return menu == 'true';
  }

  public setMenuOpen(menuOpen: boolean): void {
    window.sessionStorage.removeItem(MENU_KEY);
    window.sessionStorage.setItem(MENU_KEY, menuOpen ? 'true' : 'false');
  }
}
