<div
  id="header"
  [ngClass]="
    menuOpen
      ? 'w-full absolute top-0 md:top-auto md:relative z-5 md:z-auto flex justify-content-between h-5rem'
      : 'w-full flex justify-content-between h-5rem'
  "
>
  <div class="ml-3 flex align-items-center">
    <i class="pi pi-bars icone-menu" style="font-size: 1.5rem" (click)="changeMenuState()"></i>
  </div>

  <div class="mr-4 md:mr-6 text-2xl flex justify-content-end align-items-center">
    <img id="logo" src="./assets/images/logo.png" />
    <i class="ml-4 pi pi-sign-out icone-menu" style="font-size: 1.5rem" (click)="logout()"></i>
  </div>
</div>
