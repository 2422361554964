<app-header (menuSatusChange)="menuSatusChange($event)" *ngIf="isLoggedIn" [menuOpen]="isMenuOpen"></app-header>

<div
  style="height: calc(100vh - 5rem)"
  [ngClass]="isMenuOpen ? 'flex flex-horizontal app-container' : 'flex flex-horizontal'"
>
  <app-sidebar *ngIf="isLoggedIn && isMenuOpen"></app-sidebar>
  <div [ngClass]="{ 'mx-3 pt-4': isLoggedIn }" style="flex: 1">
    <router-outlet></router-outlet>
  </div>
</div>

<p-toast position="top-right"></p-toast>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#EF702E" type="square-jelly-box">
  <p style="font-size: 20px; color: white">Aguarde...</p>
</ngx-spinner>
