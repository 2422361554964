import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'planejamento-orcamentario', pathMatch: 'full' },
  {
    path: 'home',
    redirectTo: 'planejamento-orcamentario',
    pathMatch: 'full',
    // loadComponent: () => import('./modules/home/home.component').then((c) => c.HomeComponent),
    // canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./modules/login/login.component').then((c) => c.LoginComponent)
  },
  {
    path: 'recuperar-senha',
    loadComponent: () => import('./modules/login/recuperar-senha/recuperar-senha.component').then((c) => c.RecuperarSenhaComponent)
  },
  {
    path: 'reset',
    loadComponent: () => import('./modules/login/reset/reset.component').then((c) => c.ResetComponent)
  },
  {
    path: 'planejamento-orcamentario',
    loadChildren: () => import('./modules/planejamento-orcamentario/planejamento-orcamentario.routes').then(mod => mod.ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'execucao-orcamentaria',
    loadChildren: () => import('./modules/execucao-orcamentaria/execucao-orcamentaria.routes').then(mod => mod.ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'ferramentas-gerenciais',
    loadChildren: () => import('./modules/ferramentas-gerenciais/ferramentas-gerenciais.routes').then(mod => mod.ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'cadastro',
    loadComponent: () => import('./modules/cadastro-usuario/cadastro-usuario.component').then((c) => c.CadastroUsuarioComponent)
  }
];

@NgModule({
  providers: [AuthGuard],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
