import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserStorageService } from '@app/core/service/user-storage.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, SidebarModule, PanelMenuModule, BrowserAnimationsModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() menuOpen: boolean = false;
  @Output() menuSatusChange = new EventEmitter<boolean>();

  constructor(private userStorageService: UserStorageService) {}

  ngOnInit(): void {}

  logout() {
    this.userStorageService.clean();
    this.reloadPage();
  }

  reloadPage(): void {
    window.location.reload();
  }

  changeMenuState(): void {
    const isMenuOpen = this.userStorageService.isMenuOpen();
    this.userStorageService.setMenuOpen(!isMenuOpen);
    this.menuSatusChange.emit(!isMenuOpen);
  }
}
