<div class="absolute app-sidebar md:top-auto md:relative z-5 h-full md:max-w-25rem w-full" style="top: 80px">
  <div class="text-center hidden md:block">
    <img id="logo-sidebar" src="./assets/images/logo.png" />
  </div>
  <div class="text-center bg-white md:hidden">
    <img id="logo-sidebar" src="./assets/images/logo.png" />
  </div>

  <p-panelMenu [model]="itens" separator="true" styleClass="w-full "></p-panelMenu>
</div>
