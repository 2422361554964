import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private storage: Storage;

    constructor() {
        this.storage = window.localStorage;
    }

    set(key: string, value: any, text: boolean = false): boolean {
        if (this.storage) {
            if (text == false) {
                this.storage.setItem(key, JSON.stringify(value));
            } else {
                this.storage.setItem(key, value);
            }
            return true;
        }
        return false;
    }

    get(key: string, text: boolean = false): any {
        if (this.storage) {
            if (text == false) {
                return JSON.parse(this.storage.getItem(key));
            } else {
                return this.storage.getItem(key);
            }
        }
        return null;
    }

    remove(key: string): boolean {
        if (this.storage) {
            this.storage.removeItem(key);
            return true;
        }
        return false;
    }

    clear(): boolean {
        if (this.storage) {
            this.storage.clear();
            return true;
        }
        return false;
    }

}