import { Component, OnInit } from '@angular/core';
import { UserStorageService } from './core/service/user-storage.service';
import { PrimeNGTranslationService } from './prime-ng-translation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn = true;
  isMenuOpen = true;
  title = 'cearapar-front';

  constructor(private userStorageService: UserStorageService, private translationService: PrimeNGTranslationService) {
    this.isLoggedIn = this.userStorageService.isLoggedIn();
    this.isMenuOpen = this.userStorageService.isMenuOpen();
  }

  ngOnInit() {
    this.translationService.setPortugueseTranslation();
  }

  menuSatusChange(isMenuOpen: boolean): void {
    this.isMenuOpen = isMenuOpen;
  }
}
