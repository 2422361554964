import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserStorageService } from '@app/core/service/user-storage.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, SidebarModule, PanelMenuModule, BrowserAnimationsModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  itens: MenuItem[];

  constructor(private userStorageService: UserStorageService) {}

  ngOnInit(): void {
    this.itens = [ 
      {
        label: 'Planejamento Orçamentário',
        items: [
          { label: 'Orçamentos', icon: 'pi pi-calendar-times', routerLink: 'planejamento-orcamentario/orcamentos' },
          {
            label: 'Disponibilidade',
            icon: 'pi pi-calendar-times',
            routerLink: 'planejamento-orcamentario/disponibilidade'
          },
          { label: 'Contratos', icon: 'pi pi-calendar-times', routerLink: 'planejamento-orcamentario/contratos' }
        ]
      },
      {
        label: 'Execução Orçamentária',
        items: [
          { label: 'Receitas', icon: 'pi pi-calendar-times', routerLink: 'execucao-orcamentaria/receitas' },
          { label: 'Contas a Pagar', icon: 'pi pi-calendar-times', routerLink: 'execucao-orcamentaria/despesas' }
        ]
      },
      {
        label: 'Ferramentas Gerenciais',
        items: [
          {
            label: 'Histórico de Ações',
            icon: 'pi pi-calendar-times',
            routerLink: 'ferramentas-gerenciais/historico-acoes'
          },
          {
            label: 'Balanço Orçamentário',
            icon: 'pi pi-calendar-times',
            routerLink: 'ferramentas-gerenciais/balanco-orcamentario'
          },
          {
            label: 'Extrato de Orçamento',
            icon: 'pi pi-calendar-times',
            routerLink: 'ferramentas-gerenciais/extrato-orcamentario'
          }
        ]
      }
    ];
    this.itens.forEach((item) => {
      item.expanded = true;
      if (item.items) {
        item.items.forEach((subItem) => (subItem.expanded = true));
      }
    });
  }

  onMenuItemClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  logout() {
    this.userStorageService.clean();
    this.reloadPage();
  }

  reloadPage(): void {
    window.location.reload();
  }
}
